@import '../variables';
@import '../mixins';

#site-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding: 1rem 2rem;

  @include transition( all .6s ease-in-out );

  &.active {
    //background-color: #1c2a45;
    background-color: rgba(25, 63, 110, 0.85);
    @include transition( all .6s ease-in-out );
  }

  &.is-active {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-image: url('/wp-content/themes/plank/static/images/hamburger-image.jpg');

    #nav-main {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 85px);
      flex-direction: column;
    }
  }

  .site-header-top {
    display: flex;
    justify-content: space-between;

    #main-logo {
      flex: 1;
      margin: 0 0 0 0;

      svg {
        max-height: 100px;
        max-width: 700px;
      }
    }
  }
}

.hamburger-active #site-header.is-active {
  background-color: #1c2a45 !important;
}


#nav-main {
  display: none;
  font-family: $serif-font;

  a {
    @extend .article-title;

    color: white;
    opacity: 0.5;
    transform: translateZ(0);
    transition: opacity .7s cubic-bezier(.23,1,.32,1);


    &:hover {
      opacity: 1;
      color: white;
      text-decoration: none;
    }
  }

  .current-menu-item a {
    opacity: 1;
  }

  ul {
    padding-left: 0;
    margin-top: 0;
  }
}

.phone-link {
  display: flex;
  margin-top: 3rem;
  svg {
    width: 35px;
    margin-top: 20px;
    margin-right: 20px;
    fill: white !important;
  }

  .value {
    color: white !important;
  }
}



