@import '../variables';

.base-hero {

  width: 100vw;
  height: 100vh;
  overflow: hidden;

  display: flex;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;

  .hero-content {
    margin-bottom: 8rem;

    .hero-title {
      color: white;
      text-shadow: $base-text-shadow;
    }

  }
}
