@import '../variables';


.pagination-block {
  ul.pagination {
    padding: 0;
    margin: $md-pad 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}