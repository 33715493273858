@import '../variables';
@import '../mixins';

.block-w-img-bg-hover {
  height: 100%;
  width: 100%;
  padding: 2rem;

  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0);
    z-index: -10;
    @include transition( all .6s ease-in-out );
  }

  img {
    object-fit: cover;
    @include transition( all .6s ease-in-out );
    transform: scale(1);
    vertical-align: bottom;

    position: absolute;
    z-index: -20;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
  }

  &:hover, &.active {
    &:after {
      background-color: rgba(135, 210, 113, 0.85);
      @include transition( all .6s ease-in-out );
    }

    img {
      opacity: 1;
      @include transition( all .6s ease-in-out );
    }
  }
}

.value-block {
  @extend .block-w-img-bg-hover;

  &.active {
    h4 {
      color: white;
    }
  }
}

.services-block {
  max-width: 930px;
  margin: 4rem auto;

  > h2 {
    margin: 8rem 0 6rem;
    text-align: center;
    font-family: $serif-font;
    color: $brand-blue;
    text-transform: uppercase;
    font-size: 32px;
  }

  .services-block__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .service-block {
    width: calc(50% - 1rem);
    max-width: 450px;
    min-height: 450px;
    border: 1px solid $site-green;
    margin-bottom: 2rem;
    position: relative;

    @media screen and (max-width: $bp-sm) {
      width: 100%;
      max-width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &:hover .child-pages {
      display: block;
    }

    .child-pages {

      @media screen and (min-width: $bp-sm) {
        display: none;
        position: absolute;
        top: 400px;
      }

      border: 1px solid $site-green;
      padding-left: 0;
      background-color: white;
      list-style: none;
      width: 90%;
      z-index: 100;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      li {
        border-bottom: 1px solid $site-green;
        &:last-child {
          border-bottom: none;
        }

        a {
          @include transition( all .3s ease-in-out );
          display: block;
          padding: 1rem;
          font-size: 18px;

          &:hover {
            @include transition( all .3s ease-in-out );
            color: white;
            background-color: $site-green;
          }
        }
      }
    }

    .service-block-link {
      @extend .block-w-img-bg-hover;
      height: inherit;

      @media screen and (min-width: $bp-sm) {
        height: 100%;
      }

      .icon {
        height: 60px;
        width: 60px;
        text-align: center;
        margin: 0 auto 2rem;
        display: block;

        path {
          fill: $site-green;
        }
      }

      .service-title {
        font-size: 24px;
        color: black;
        margin: 1rem 0;
        text-align: center;
      }

      .service-snippet {
        color: #656565;
        font-size: 18px;
        margin-top: 10px;
      }



    }

    a:hover {
      text-decoration: none;

      .icon path {
        fill: white;
        @include transition( all .6s ease-in-out );
      }

      .service-title, .service-snippet {
        color: white;
        @include transition( all .6s ease-in-out );
      }

      .service-children {
        display: none;
      }
    }
  }
}

