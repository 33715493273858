@import '../variables';
@import '../mixins';

footer {
  position: relative;
  text-align: left;
  margin-bottom: 0;

  .content-wrapper {
    @extend .base-content-wrapper;
    margin: 6rem auto;

    .content {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem;
      justify-content: space-between;
      background-color: rgba(255,255,255,0.85);

      @media screen and (min-width: $bp-m) {
        padding: 3rem;
      }

      .content-right {
        max-width: 390px;

        p {
          font-size: 18px;
        }
      }

    }
  }

  .fullscreen-bg:before {
    background-color: rgba(28,43,69, 0.6);
  }

  h2 {
    @extend .article-title;
  }

  .contact-text {
    font-family: $serif-font;
    margin-bottom: 2rem;
  }

  .footer-logo {
    margin-bottom: 2rem;
  }

  ul {
    list-style: none;
    padding-left: 0;
  }

}

footer .made-by {
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 0 1rem;
  left: 0;
  right: 0;
  font-size: 14px;
  color: #666;
  background-color: rgba(0,0,0,0.5);

  a {
    color: #666;
  }
}
