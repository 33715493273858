@import '../variables';
@import '../mixins';

.home {
  .section {
    position: relative;
  }

  .hero {
    @extend .base-hero;
  }

  .content {
    .article-h4 {
      text-transform: uppercase;
      color: $site-green;
    }

    .article-title {
      margin-bottom: 4rem;
    }
  }
}



.home .value-blocks-section {
  position: relative;
  height: 350px;
  margin-bottom: 2rem;

  .content {
    position: absolute;
    display: flex;
    justify-content: center;
    margin-top: -100px;
    z-index: 100;
    right: 0;
    left: 0;

    @media screen and (max-width: $bp-sm) {
      margin-top: 0;
    }

  }

  .value-blocks {
    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .value-block {
      position: relative;
      width: 33%;
      height: 350px;
      box-shadow: #000 2px 4px 8px;
      background-color: white;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      @include transition( all .6s ease-in-out );

      .icon {
        width: 50px;
        height: 50px;
        align-self: center;

        path {
          fill: $site-green;
        }
      }

      .value-description {
        display: none;
        color: white;
        font-size: 17px;
        margin-top: 20px;
        width: 100%;
      }

      &.active {
        @include transition( all .6s ease-in-out );
        transform: scale(1.1);
        z-index: 1;

        .icon path {
          fill: white;
        }

        .value-description {
          display: inherit;
        }
      }

      @media screen and (max-width: $bp-sm) {
        min-width: 100%;
        margin-bottom: 1rem;
        background-color: transparent;

        &.active {
          transform: scale(1);
        }

        img {
          opacity: 1;
        }

        .icon path {
          fill: white;
        }

        h4 {
          color: white;
        }
        .value-description {
          display: inherit;
        }

        &:after {
          background-color: rgba(135, 210, 113, 0.85);
        }
      }
    }
  }

}

.home .profiles-block {
  background-color: inherit;

  .profile.block {
    box-shadow: #000 2px 4px 8px;
  }
}

.services-block {
  > h2 {
    margin: 2rem 0 4rem;
  }
}

.info-block {
  display: none;
  float: right;
  position: relative;
  margin-left: 1rem;
  margin-bottom: 1rem;

  @media screen and (min-width: $bp-sm) {
    display: block;
    width: 450px;
  }


    .info-content {
      display: flex;
      position: absolute;
      width: 450px;
      height: 100%;
      top: 0;
      color: #8B8B8B;
      justify-content: center;
      align-items: center;

    .info-number {
      font-size: 70px;
      font-weight: bold;
      width: 530px;
      margin: 40px;
      line-height: 1;
      background-color: rgba(255,255,255,0.7);
    }
    .info-text {
      font-size: 18px;
    }
  }

}

.pie-chart-wrapper {
  width: 250px;

  .pie-chart {
    transform: rotate(180deg);

    .line {
      fill: none;
    }

    &.active .line {
        animation: progress 1s ease-out forwards;
    }
  }

}

@keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
}