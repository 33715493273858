@import '../variables';

.fullscreen-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;

  &:before {
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    position: absolute;
    background-color: rgba(25, 63, 110, 0.65);
  }

}

.fullscreen-bg__media {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (max-aspect-ratio: 16/9) {
    width: auto;
    height: 100%;
  }

  @media (min-aspect-ratio: 16/9) {
    width: 100%;
    height: auto;
  }
}

.fullscreen-bg__image {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.profile-page .fullscreen-bg__image {
  @media screen and (max-width: $bp-sm) {
    object-position: 75% 0;
  }
}

.fullscreen-bg__video {
  @extend .fullscreen-bg__media;
}