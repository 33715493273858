@import '../variables';
@import '../mixins';

.singular-article {
  margin-bottom: 0;
}

#singular-article__hero {
  @extend .base-hero;
  align-items: flex-end;
}

.singular-article__content {
  @extend .base-content-wrapper;
  margin-top: 100vh;
  padding-top: 1rem;
  padding-bottom: 1rem;

  h1 {
    @extend .article-title;
    margin-top: 3rem;
  }

  h2 {
    @extend .article-secondary-title;
    margin-top: 4rem;
    margin-bottom: 2rem;
  }

  h4, h5, h6 {
    @extend .article-tertiary-title;
    margin-top: 2rem;
    margin-bottom: 1rem;
    color: #3a3a3a;
  }
}

.breadcrumb a {
  color: $site-green;
}

.singular-article__aside-content-bottom {
  background-color: #1C2B45;
  margin-bottom: 0;
  overflow: hidden;
  padding: 6rem 0;

  .inner {
    @extend .base-content-wrapper;
    justify-content: space-between;
  }

  .blog-posts-title {
    @extend .article-title;
    margin: 2rem 0;
    flex: 0 0 100%;
  }
}

.blog-posts-grid {
  @extend .base-block-grid;

  .block {
    max-width: 350px;
    width: 100%;
    display: flex;

    @media screen and (min-width: $bp-m) {
      width: 48%;
    }

    @media screen and (min-width: $bp-lg) {
      width: 32%;
    }

    a {
      background-color: white;
      display: block;
      @include transition( all .6s ease-in-out );
    }

    h4 {
      padding: $base-padding;
      margin: 0 0 0 0;
    }

    h6 {
      padding: 0 $base-padding $base-padding $base-padding;
      margin: 0 0 0 0;
    }

    .img-wrapper {
      overflow: hidden;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        z-index: 1;
        opacity: 0;
        @include transition( all .6s ease-in-out );
      }
    }

    img {
      object-fit: cover;
      @include transition( all .6s ease-in-out );
      transform: scale(1);
      vertical-align: bottom;
    }

    a:hover {
      text-decoration: none;
      background-color: $site-green;
      @include transition( all .6s ease-in-out );

      .img-wrapper:before {
        @include transition( all .6s ease-in-out );
        opacity: 0.4;
      }

      img {
        transform: scale(1.05);
        @include transition( all .6s ease-in-out );
      }

      h4, h6 {
        color: white;
        @include transition( all .6s ease-in-out );
      }

    }

  }
}

.profiles-block {
  @media screen and (max-width: $bp-lg) {
    padding-bottom: 0;
    padding-top: 0;
  }

  @media screen and (min-width: $bp-lg) {
    height: 300px;
  }

}

.profiles-grid {
  @extend .blog-posts-grid;
  justify-content: center;
  z-index: 100;
  left: 0;
  right: 0;

  @media screen and (min-width: $bp-lg) {
    margin-top: -350px;
    position: absolute;
  }


  .block h4 {
    padding-bottom: 0.25em;
  }
  .block h6 {
    text-transform: uppercase;
  }
}

.our-team-page {
  .profiles-block {
    height: auto;
    background-color: white;
  }
  .profiles-grid {
    position: inherit;
    margin-top: 0;
  }
  .profile {
    margin-bottom: 60px;
  }
}

.profile-page {
  .hero-content {
    .profile-title {
      color: white;
      font-family: $serif-font;
      font-size: 32px;
    }

    .profile-certifications {
      color: white;
      margin-top: 10px;
    }

    .contact-meta-block {
      justify-content: flex-start;

      ul {
        list-style: none;
        padding-left: 0;

        a {
          color: white;
          align-items: center;
          margin-bottom: 0;
        }

      }
    }
  }

  .fullscreen-bg:before {
    background-color: transparent;
  }
}

.testimonial-block {
  background-color: transparent;
  position: relative;
  padding-bottom: 310px;

  .testimonial-block__content {
    @extend .base-content-wrapper;

    h2 {
      color: $site-green;
      font-size: 24px;
      font-family: $serif-font;
      text-shadow: #000 0 2px 4px;
    }

    .testimonial-block__testimonial {
      color: white;
      font-size: 18px;
      line-height: 1.3;

      @media screen and (min-width: $bp-sm) {
        font-size: 24px;
        line-height: 1.2;
      }

    }

    .testimonial-block__author {
      color: $site-green;
      text-align: right;
      font-size: 24px;
      text-shadow: #000 0 2px 8px;

      &:before {
        content: '~';
      }

      @media screen and (min-width: $bp-sm) {
        font-size: 36px;
      }

    }

    hr {
      margin: 3rem 0;
      border-top: 1px solid #d8d8d8;
    }

  }

  .fullscreen-bg:before {
    background-color: rgba(63, 63, 63, 0.65);
  }
}

.post-author {
  color: white;
  text-transform: capitalize;
}

.categories-sidebar {
  min-width: 320px;
  h3 {
    @extend .slab-blue-font;
    color: white;
    border-bottom: 1px solid white;
    font-weight: normal;
  }
  ul {
    color: white;
    padding-left: 22px;
    column-count: 1;

    @media screen and (min-width: $bp-sm) {
      column-count: 2;
    }

    @media screen and (min-width: $bp-m) {
      column-count: 3;
    }

  }

  li {
    font-size: 18px;
    a {
      color: white;

      &:hover {
        color: $site-green;
      }
    }
  }

}
