@import 'variables';
@import '~marx-css/scss/marx';
@import '~hamburgers/_sass/hamburgers/hamburgers';

@import 'base';

@import 'partials/pagination';
@import 'partials/blocks';
@import 'partials/site-header';
@import 'partials/fullpage-media';
@import 'partials/hero';
@import 'partials/footer';

@import 'sections/singular';
@import 'sections/front-page';

body {
  overflow-x: hidden;
}

main {
  max-width: inherit;
  padding: 0 0 0 0;
}

a {
  color: $site-blue;
  @include transition( all .3s ease-in-out );

  &:hover {
    color: $site-green;
    text-decoration: none;
    @include transition( all .3s ease-in-out );
  }
}

h3 {
  @extend .article-tertiary-title;
  margin: 2rem 0 1rem;
}

strong {
  font-weight: bold;
}

iframe.google-map {
  width: 50%;
  min-height: 300px;
  margin-bottom: 1rem;
  margin-left: 1rem;
  float: right;

  @media screen and (max-width: $bp-m) {
    width: 100%;
    margin-left: 0;
  }
}

.font-white {
  color: white;
}

.icon {
  width: 22px;
  height: 22px;
  margin-top: 8px;
  margin-right: 10px;
  align-self: flex-start;
}

.contact-meta-block {
  display: flex;
  justify-content: center;

  li a {
    display: flex;
    margin-bottom: 1rem;
    @include transition( all .3s ease-in-out );

    &:hover {
      color: $site-blue !important;
      @include transition( all .3s ease-in-out );
    }
  }
}

.social-links-block {
  .social-links {
    display: flex;
    justify-content: center;
  }
}

.tool-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $site-green;
  margin-bottom: 3rem;

  .prev, .next {
    border: 1px solid white;
    padding: 1rem;
  }

  a {
    color: white;

    &:hover {
      color: $site-green;
    }
  }

  .pages {
    flex: 1;
    display: flex;
    list-style: none;
    justify-content: space-around;
    margin: 0 0 0 0;
  }
}

// Marx overrides
.button {
  @extend button;
  -webkit-appearance: none; // Fix for weird implementation see: https://developer.mozilla.org/en-US/docs/Web/CSS/appearance
  -moz-appearance: none;
}


