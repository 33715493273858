@import url('https://fonts.googleapis.com/css?family=Roboto+Slab|Roboto:400,400i,700&display=swap');
@import '~marx-css/scss/variables'; // Import marx variables;


// Hamburger SCSS overrides
$hamburger-layer-color: white;

// Colors
$brand-blue: #3f5774;
$brand-green: #8cb83f;
$site-blue: #193F6E;
$site-green: #87D271;

// Fonts
$serif-font: 'Roboto Slab', serif;
$sans-serif-font: 'Roboto', sans-serif;

$base-padding: $md-pad;
$base-border: 1px solid rgba(0, 0, 0, 0.12);
$base-text-shadow: #000 0 2px 4px;
$base-background-color: $grey;
$base-font-size: 24px;

$content-width: 1170px;

// Breakpoints
$bp-sm: 650px;
$bp-m: 800px;
$bp-lg: 1200px;