@import 'variables';

// CONTENTS
// 1. Typography
// 2. Headers
// 3. Layout
// 4. Forms


//
// Typography
//
body {
  font-size: 18px;
  @media screen and (min-width: $bp-m) {
    font-size: 24px;
  }
}

//
// HEADERS
//
.base-title {
  font-size: 28px;
  @media screen and (min-width: $bp-sm) {
    font-size: 34px;
  }
}

.slab-blue-font {
  font-family: $serif-font;
  color: $site-blue;
}

.hero-title {
  font-size: 34px;
  @media screen and (min-width: $bp-m) {
    font-size: 48px;
  }
  @media screen and (min-width: $bp-lg) {
    font-size: 72px;
  }
  font-family: $serif-font;
  color: white;
  text-shadow: #000 0 2px 4px;
}

.article-title {
  @extend .slab-blue-font;
  font-size: 24px;
  @media screen and (min-width: $bp-sm) {
    font-size: 34px;
  }
  @media screen and (min-width: $bp-m) {
    font-size: 48px;
  }
}

.article-secondary-title {
  @extend .base-title;
  @extend .slab-blue-font;
  text-transform: uppercase;
}

.article-tertiary-title {
  color: $site-green;
  font-weight: bold;
  font-size: 24px;
  @media screen and (min-width: $bp-m) {
    font-size: 28px;
  }
}

//
// Layout
//
.base-content-wrapper {
  max-width: $content-width;
  margin: 0 auto;
  padding: $base-padding;
  width: 100%;
}

article {
  margin-bottom: 0;
}

main {
  .content {
    @extend .base-content-wrapper;
  }
}

.base-block-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .block {
    margin-bottom: 60px;
  }
}

footer {
  border-top: none;
}


//
// Forms
//

form {
  input, textarea {
    margin-bottom: 1rem;
  }
}